<template>
  <div>
    <!-- 信息页 -->
    <div class="header-box" />
    <!-- banner -->
    <div
      v-if="screenWidth>1200"
      class="banner"
      :style="{ backgroundImage: 'url('+banner+')', backgroundPosition: 'center -100px', backgroundRepeat: 'no-repeat', backgroundColor: '#1b1b1b'}"
    />
    <div
      v-else
      class="banner"
      :style="{ backgroundImage: 'url('+banner+')', backgroundPosition: 'center 60px', backgroundRepeat: 'no-repeat', backgroundColor: '#1b1b1b', backgroundSize: '100%, 100%'}"
    />

    <!-- 内容 -->
    <div class="container">
      <div class="detail">
        <div :class="[screenWidth > 1200 ? 'long-box' : '']">
          <div class="menu">
            <div v-for="(menu, idx) in menuList" :key="menu">
              <div :class="[idx == selectIndex ? 'select' : '', 'menu-item']" @click="selectDetail(idx)">
                <div class="menu-border">
                  <div class="svg">
                    <svg-icon name="diamond" color="#fff" width="20" height="20" />
                  </div>
                  <div class="menu-title">{{ menu }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="content-header">
              <!-- <div class="content-svg">
                  <svg-icon name="diamond" color="#581663" width="40" height="40"></svg-icon>
              </div> -->
              <div class="content-title">{{ menuList[selectIndex] }}</div>
            </div>
            <!--            <div class="content-text">-->
            <!--              <p>-->
            <!--                <img style="width: 50px" :src="banner">-->
            <!--              </p>-->
            <!--            </div>-->
            <div ref="content" class="content-text" v-html="content" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import '@/icons/diamond'
import { fetchResource } from '@/api/homepage'
import { retrieveContestDetail, retrieveContestNotice } from '@/api/homepage'

export default {
  data() {
    return {
      // 界面大小
      screenWidth: '',
      screenHeight: '',

      // jumpId
      jumpId: '',

      banner: '',

      menuList: ['大赛详情', '大赛须知'],
      selectIndex: 0,
      // banner: ''

      content: '',
      contestDetails: '这是大赛详情',
      contestNotice: '这是大赛须知'
    }
  },
  created() {
    // console.log('I am info page')
  },
  mounted() {
    // 实时监听窗口大小的变化
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
        this.screenHeight = document.body.clientHeight
        // console.log('width ' + this.screenWidth)
        // console.log('height ' + this.screenHeight)
      })()
    }
    this.init()

    // console.log('===============')
    this.jumpId = this.$route.params.part
    // window.vue = this
  },
  methods: {
    // 初始化, 获取大赛banner
    init() {
      this.content = this.contestDetails
      this.fetchContestDetails()
      this.fetchContestNotice()
      this.fetchResource()
    },
    // 获取banner图
    fetchResource() {
      fetchResource().then(res => {
        const resource = res.data
        resource.bannerDir = this.PR.previewOrigin(resource.banner)
        this.banner = `${resource.bannerDir}/banner.jpg`
      })
    },
    // 从本地找到id
    getLocal() {
      if (this.jumpId) {
        const maodian = `#${this.jumpId}`
        const anchor = this.$el.querySelector(maodian)
        document.documentElement.scrollTop = anchor.offsetTop - 70
      }
    },
    // 根据选择下标获取相应 详情/须知 内容
    selectDetail(index) {
      console.log(index)
      switch (index) {
        case 0:
          this.content = this.contestDetails
          break
        case 1:
          this.content = this.contestNotice
          break
        default:
      }
      this.selectIndex = index
    },
    getSubHTML(id, subvalue, tagname) {
      let parser
      // eslint-disable-next-line prefer-const
      parser = new DOMParser()
      const substr1 = '<' + tagname + ' id="' + id + '"></' + tagname + '>'
      // console.log('getsubhtml', substr1)
      let subxml = parser.parseFromString(substr1, 'text/html')
      subxml.getElementById(id).innerHTML = subvalue
      subxml = subxml.getElementsByTagName(tagname)[0]
      return subxml
    },
    parseHTML(text) {
      // 解析xml并做相应的更改
      let parser, xmlDoc, subxml, idx
      // eslint-disable-next-line prefer-const
      parser = new DOMParser()
      // eslint-disable-next-line prefer-const
      xmlDoc = parser.parseFromString(text, 'text/html')
      // console.log(xmlDoc)

      // 找大赛主题标签
      // console.log(xmlDoc.getElementsByTagName("h4"))
      const taglist = [/h1/, /h2/, /h3/, /h4/, /h5/, /h6/]
      const tagname = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
      for (idx = 0; idx < taglist.length; idx++) {
        if (taglist[idx].test(text)) {
          break
        }
      }
      // console.log('index', idx)
      const tags = xmlDoc.getElementsByTagName(tagname[idx])
      // console.log('tags', tags)
      for (let k = 0; k < tags.length; k++) {
        const oldNode = tags[k]
        const value = oldNode.childNodes[0].nodeValue
        // console.log('innn', oldNode)
        if (value.search('大赛主题') !== -1) {
          subxml = this.getSubHTML('dasaizhuti', value, tagname[idx])
        } else if (value.search('参赛对象') !== -1) {
          subxml = this.getSubHTML('cansaiduixiang', value, tagname[idx])
        } else if (value.search('大赛类别') !== -1) {
          subxml = this.getSubHTML('dasaineirong', value, tagname[idx])
        } else if (value.search('大赛评审') !== -1) {
          subxml = this.getSubHTML('dasaipingshen', value, tagname[idx])
        } else if (value.search('参赛要求') !== -1) {
          subxml = this.getSubHTML('cansaiyaoqiu', value, tagname[idx])
        } else if (value.search('奖项设置') !== -1) {
          subxml = this.getSubHTML('jiangxiangshezhi', value, tagname[idx])
        } else if (value.search('联系方法') !== -1) {
          subxml = this.getSubHTML('lianxifangfa', value, tagname[idx])
        } else if (value.search('大赛监督') !== -1) {
          subxml = this.getSubHTML('dasaijiandu', value, tagname[idx])
        }
        xmlDoc.getElementsByTagName('body')[0].replaceChild(subxml, oldNode)
      }
      // console.log('xmlDoc', xmlDoc)
      return xmlDoc
    },
    // 获取大赛详情
    async fetchContestDetails() {
      console.log('get banner')
      await retrieveContestDetail().then(Response => {
        // console.log(Response.data)

        const ctt = Response.data.content
        const patt = /width="[0-9]+" height="[0-9]+"/
        const img_patt = /<p><img/
        let res = ctt.replace(img_patt, '<p style="text-align: center"><img')

        res = res.replace(patt, 'width="65%" height="auto"')
        // console.log(res)

        // res = this.parseHTML(res)

        // // 序列化
        // const serializer = new XMLSerializer()
        // res = serializer.serializeToString(res)
        // console.log('res',res)

        this.contestDetails = res
        this.selectDetail(0)

        this.$nextTick(() => this.getLocal())
      })
    },
    // 获取大赛须知
    async fetchContestNotice() {
      await retrieveContestNotice().then(res => {
        this.contestNotice = res.data.content
      })
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

/* .content-text p:has(> img) {
  text-align: center
} */

.banner {
  width: auto;
  height: 550px;
  background-color: var(--background-color);
}

@media screen and (max-width: 1200px) {
  .banner {
    height: 250px;
    background-color: var(--background-color);
  }
}

.container {
  /* width: 100%; */
  text-align: center;
  padding-top: 30px;
  /* border: 1px solid palegreen; */
  background-color: var(--background-light-color);
}

@media screen and (max-width: 1200px) {
  .container {
    padding-top: 20px;
    background-color: var(--background-color);
  }
}

.detail {
  display: inline-block;
  /* display: -webkit-box; */
  margin: auto;
  margin-bottom: 50px;
}

.long-box {
  display: -webkit-box;
}

/* 菜单栏 */
.menu {
  /* display: table-cell; */
  width: 250px;
  background-color: #fff;
  border-radius: 10px;
  /* padding-bottom: 2 */
  min-height: 310px;
}

@media screen and (max-width: 1200px) {
  .menu {
    /* display: inline-block; */
    width: 330px;
    margin: auto;
    min-height: 130px;
    margin-bottom: 20px;
  }
}

.select {
  background-color: var(--theme-color);
  color: #fff;
}

.menu-item {
  display: inline-block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  text-align: left;
  /* border: 1px solid red; */
}

.menu-border {
  /* display: inline-block; */
  /* margin: 0 30px; */
  width: 80%;
  margin: auto;
  border-bottom: 1px solid rgb(223, 219, 219);
}

.svg {
  display: inline-block;
  position: relative;
  line-height: 60px;
  top: 4px;
  /* right: -12px; */
  /* border: 1px solid red; */
}

.menu-title {
  display: inline-block;
  margin-left: 25px;
  /* border: 1px solid rgb(68, 68, 95); */
}

.menu-title:hover {
  cursor: pointer;
}

/* 内容 */
.content {
  /* display: table-cell; */
  width: 800px;
  margin-left: 50px;
  background-color: #fff;
  border-radius: 10px;

  /* border: 1px solid red; */
}

@media screen and (max-width: 1200px) {
  .content {
    display: inline-block;
    width: 330px;
    margin: auto;
    /* min-height: 130px; */
    margin-bottom: 20px;
  }
}

.content-header {
  /* position: relative; */
  height: 90px;
  text-align: left;
  padding-top: 20px;
  /* border: 1px solid red; */
  /* line-height: 100px; */
}

.content-svg {
  position: absolute;
  top: 30px;
  left: 20px;
}

.content-title {
  width: 90%;
  margin: auto;
  /* margin-top: 50px; */
  font-size: 24px;
  padding-left: 25px;
  line-height: 60px;
  /* border: 1px solid red; */
  border-bottom: 1px solid rgb(37, 37, 37);

}

.content-text {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  min-height: 200px;
  margin-bottom: 50px;
  text-align: left;
}
</style>
